import { createAPI } from "../utils/request"

export const hospdataLists = data => createAPI('base/hosp/list', 'get', data) //医院列表

export const hospAdd = data => createAPI('base/hosp/create', 'post', data) //医院新增

export const hospEdit = data => createAPI('base/hosp/save', 'post', data) //医院修改

export const hospCheck = data => createAPI(`base/hosp/get/${data.id}`, 'get', data) //获取医院信息

export const hospDelete = data => createAPI(`base/hosp/delete/${data.id}`, 'get', data) //删除医院信息

export const departadd = data => createAPI(`base/depart/create`, 'post', data) //新增科室

export const departDelete = data => createAPI(`base/depart/delete/${data.id}`, 'get', data) //删除科室信息

export const departedit = data => createAPI(`base/depart/save`, 'post', data) //编辑科室信息

export const departdata = data => createAPI(`base/depart/get/${data.id}`, 'GET', data) //科室详细信息








<template>
  <div>
      <q-form ref="myForm" greedy class="q-gutter-md add">
        <div class="row">
          <div class="col kong">
            <!-- 省份选择 -->
            <selectp @provincechange='provincechange' class='add' :options='checkAlllists.province' :model.sync='editDatas.provinceoptions' :label='"请选择省份*"' />
          </div>
          <div class="col kong">
            <!-- 城市选择 -->
            <selectc @citychange='citychange' class='add' :options='checkAlllists.city' :model.sync='editDatas.cityoptions' :label='"请选择城市*"'/>
          </div>
          <div class="col kong">
            <!-- val => val && val.trim().length > 0 || '医院名称不能为空'  required.addhospname  -->
            <q-input 
              outlined 
              :dense="true" 
              v-model="addqueryDatas.hospName" 
              label="请输入医院" 
              lazy-rules 
              :rules="[
                val => val && val.trim().length > 0 || '医院名称不能为空',
                val =>val && val.trim().length <= 20 || '医院名称不能超过20个字', 
              ]" 
            />
          </div>
        </div>
          <div class="adddepart">
            <q-input class='kong' outlined :dense="true" v-model="editDatas.chaxun" label="请输入科室名称搜索">
              <template v-slot:append>
                <q-icon name="close" v-if="editDatas.chaxun" @click.stop="qingchu" class="cursor-pointer" style="font-size: 16px;"/>
                <q-icon name="search" @click='getallderpart'/>
              </template>
            </q-input>
            <q-btn class='kong' :disable='addqueryDatas.hospName.trim().length==0' color="blue-6" label="添加科室" @click='addDeparts'/>
          </div>
          <div>
            <el-table
            :data="checkAlllists.depart==null?[]:checkAlllists.depart"
            style="width: 100%"
            max-height="350">
            <el-table-column
              fixed
              prop="departName"
              label="科室名称">
            </el-table-column>
            <!-- <el-table-column
              fixed
              prop="datedesc"
              label="科室描述">
            </el-table-column> -->
            <el-table-column
              align='center'
              label="操作"
              width="120">
              <template slot-scope="anniu">
                <el-button
                  type="text"
                  size="small"
                  @click='deldepart(anniu.row)'
                  >
                  移除
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  @click='edit(anniu.row)'
                  >
                  编辑
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          </div>

        <div class="addbtn">
          <q-btn
              class="kong"
              flat
              label="取消"
              color="primary"
              v-close-popup
              @click="quxiao"
          />
          <q-btn
              label="保存"
              color="blue-6"
              @click="editHospList"
          />
        </div>
      </q-form>
      <!-- 添加科室弹窗 -->
      <q-dialog v-model="departAdd" persistent @hide='closeadddepart'>
        <q-card style="min-width: 550px">
          <q-card-section>
            <div class="text-h6">添加科室</div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <q-form ref="adddepart" greedy>
              <div class='departtop'>
                <q-input outlined :dense="true" v-model="departName" label="请输入科室名称" 
                  :rules="[val => val.trim().length <= 20 || '科室名称不可大于20个字符',]"
                />
              </div>
            </q-form>
            <!-- <div class='departtop'>
              <q-input outlined :dense="true" v-model="departDesc" label="请输入科室描述" />
            </div> -->
          </q-card-section>

          <q-card-actions align="right">
            <q-btn class="kong" flat label="取消" color="primary" v-close-popup />
            <q-btn label="添加" color="primary" @click='okadd'/>
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- 编辑科室弹窗 -->
      <q-dialog v-model="departEdit" persistent>
        <q-card style="min-width: 550px">
          <q-card-section>
            <div class="text-h6">编辑科室</div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <q-form ref="editdepart" greedy>
              <div class='departtop'>
                <q-input outlined :dense="true" v-model="editdepart.departName" label="请输入科室名称" 
                  :rules="[val => val.trim().length <= 20 || '科室名称不可大于20个字符',]"
                />
              </div>
            </q-form>
            
            <!-- <div class='departtop'>
              <q-input outlined :dense="true" v-model="editdepart.departDesc" label="请输入科室描述" />
            </div> -->
          </q-card-section>

          <q-card-actions align="right">
            <q-btn class="kong" flat label="取消" color="primary" v-close-popup />
            <q-btn label="保存" color="primary" @click='okedit'/>
          </q-card-actions>
        </q-card>
      </q-dialog>

  </div>
</template>

<script>
import selectp from '@/components/Order/select/provinceselect.vue' // 省份下拉框组件
import selectc from '@/components/Order/select/cityselect.vue' // 城市下拉框组件

import { provinceList,cityList,hospList } from '@/api/City.js'; // 获取城市接口
import { getprovince,getcity,gethosplist,getcombo,getoptions } from '@/api/components/getCity.js'; // 获取城市接口
import { getdepart } from '@/api/components/checkcitys.js'; // 获取城市接口
import { departedit,departDelete,departadd,departdata,hospEdit } from '@/api/Hosp.js'; // 医院增删改查接口
import  {required}  from '@/api/components/required.js' // 表单验证规则
import { notifys } from '@/api/components/Notify.js'

export default {
  created(){
  },
  
  data() {
    return {
        input4:'',
        required,

        departAdd: false, //添加套餐弹框
        departName: '', //科室名称
        departDesc: '', //科室描述

        // 编辑科室弹窗信息
        departEdit: false, //添加套餐弹框
        editdepart:{}, //编辑科室信息

        tableData: [],

        province:[], //省
        city:[], // 市
        hosplist:[], // 医院

        provincevale: null, // 省份选择id值
        required: {
            kong: ()=>{},
            comboName: val => val && val.trim().length > 0 || '套餐名称不能为空',
            comboNamelength: val => val && val.trim().length <= 20 || '套餐名称长度不能超过20个字符',
            comboDesc: val => val && val.trim().length > 0 || '套餐描述不能为空',
            comboDesclength: val => val && val.trim().length <=80 || '套餐描述长度不能超过80个字符',
            comboAmt: val => val && val.trim().length > 0 || '套餐金额不能为空',
            limit: val=> val && val>0 && val<= 999999.99 || '套餐金额必须在0~999999.99之间'
    
        },
    };
  },
  props:{
    addqueryData:{
      type: Object,
      required: true
    },
    checkAlllist: { //所有城市省份医院信息
      type: Object,
      required: true,
    },
    editData:{ //修改订单的数据
      type: Object, 
      required: true,
    },
  },
  components:{
    selectp,
    selectc,
  },
  computed:{
    addqueryDatas:{
      get(){
        return this.addqueryData
      },
      set(v){
        this.$emit('update:addqueryData',v)
      }
    },
    checkAlllists:{
        get(){
          return this.checkAlllist
        },
        set(v){
            this.$emit('update:checkAlllist',v)
        }
    },
    editDatas:{
      get(){
        return this.editData
      },
      set(v){
        this.$emit('update:editData',v)
      }
    },
  },
  watch:{
  },
  methods: {
    // 获取医院的科室信息
    async getallderpart(){
      console.log(this.editDatas.hospId);
      //获取科室
      this.checkAlllists.depart= await getdepart(this.editDatas.hospId,this.editDatas.chaxun) //获取所有科室
    },
    //清除搜索框触发
    qingchu(){
      this.editDatas.chaxun = ''
      this.getallderpart()
    },
    // 省份选择发生改变时  
    provincechange(){
      this.editDatas.cityoptions=''
      this.checkAlllists.city=[]
      this.checkAlllists.hosp=[]
      console.log(this.editDatas);
      getcity(this.checkAlllists.city,this.editDatas.provinceoptions.id)
    },
    // 城市变化时获取医院列表
    citychange(){
      this.checkAlllists.hosp=[]
      gethosplist(this.checkAlllists.hosp,this.editDatas.cityoptions.id) //获取医院信息用于检索
      console.log(this.checkAlllists.hosp);
    },
    quxiao(){
      this.$emit("editdialogclose")
    },
    // 保存医院
    editHospList(){
      this.$refs.myForm.validate().then( outcome =>{
        if (outcome){
          // 保存之前检查添加医院是否已经存在
          this.$q.dialog({
            title: '保存',
            message: '您是否保存此医院信息',
            ok: { label: '确定'},
            cancel:{ label: '取消',flat: true}
          }).onOk( async() => {
            // console.log('OK')
            const { data:res }  = await hospEdit({
              hospName: this.addqueryDatas.hospName, //科室名称
              cityId: this.editDatas.cityoptions.id, //城市id
              id: this.editDatas.hospId, //医院id
            })
            if(res.code===20000){
              this.$emit('editdialogclose')
              this.$emit('editcombosuccess')
            }
          })
        }
      })
    },

    //点击添加科室
    addDeparts(){
      this.departAdd =true
    },
    // 关闭添加科室窗口时
    closeadddepart(){
      this.departName=''
      this.departDesc=''
    },
    // 添加可以保存
    okadd(){
      this.$refs.adddepart.validate().then(async outcome =>{
        if(outcome){
          this.$q.dialog({
            title: '添加',
            message: '您是否要添加此科室',
            ok: { label: '确定'},
            cancel:{ label: '取消',flat: true}
          }).onOk( async () => {
            console.log(this.editDatas.hospId);
            const { data:res }  = await departadd({
              departName: this.departName, //科室名称
              hospId: this.editDatas.hospId, //医院id
            })
            if(res.code===20000){
              console.log(res);
              notifys('positive',res.message)
              this.departAdd =false
              this.getallderpart()
            }else{
              notifys('negative',res.message)
            }
          })
        }
      })
    },
    // 移除科室
    deldepart(row){
      console.log(row);
      this.$q.dialog({
        title: '删除',
        message: '您是否要删除此科室',
        ok: { label: '确定'},
        cancel:{ label: '取消',flat: true}
      }).onOk( async () => {
        const { data:res }  = await departDelete({
          id: row.id
        })
        if(res.code===20000){
          console.log(res);
          notifys('positive',res.message)
          this.getallderpart()
        }else{
          notifys('negative',res.message)
        }
      })
    },

    // 点击编辑
    async edit(row){
      const { data:res } = await departdata({
        id: row.id
      })
      console.log(res);
      if(res.code===20000){
        this.editdepart = res.data.depart
      }
      console.log(this.editdepart);
      this.departEdit = true
    },
    // 编辑科室保存
    okedit(){
      this.$refs.editdepart.validate().then( async outcome =>{
        if(outcome){
          this.$q.dialog({
            title: '编辑',
            message: '您是否要修改此科室名称',
            ok: { label: '确定'},
            cancel:{ label: '取消',flat: true}
          }).onOk( async () => {
            const { data:res }  = await departedit({
              ...this.editdepart
            })
            if(res.code===20000){
              console.log(res);
              this.departEdit = false
              this.getallderpart()
            }
          })
        }
      })
    }
  },
};
</script>

<style lang='less' scoped>
.adddepart{
  display: flex;
  // flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 0;
}
.departtop{
  margin: .625rem 0;
}
.kong{
  margin: 0 .625rem !important;
}
.operation{
    width: 100%;
    margin-top: 0;
}
.addbtn {
    display: flex;
    justify-content: flex-end;
}
</style>

import { createAPI } from "../utils/request"

export const comboList = data => createAPI('base/combo/list', 'get', data) //套餐列表

export const comboAdd = data => createAPI('base/combo/create', 'post', data) //套餐新增

export const comboEdit = data => createAPI('base/combo/save', 'post', data) //套餐修改

export const searchhosp = data => createAPI('base/combo/hosp/list', 'get', data) //查询套餐内 指定医院信息

export const combohospadd = data => createAPI('base/combo/hosp/add', 'post', data) //指定医院自定义金额

export const combohospdel = data => createAPI('base/combo/hosp/delete', 'get', data) //删除指定医院

export const combohospedit = data => createAPI('base/combo/hosp/edit', 'post', data) //修改指定医院自定义金额

export const comboCheck = data => createAPI(`base/combo/get/${data.comboId}`, 'get', data) //获取套餐信息

export const comboDelete = data => createAPI(`base/combo/delete/${data.comboId}`, 'get', data) //删除套餐数据

export const hospMapAdd = data => createAPI(`base/combo/hosp/map/add`, 'post', data) //添加关联医院

export const hospMapDel = data => createAPI(`base/combo/hosp/map/delete/${data.id}`, 'get', data) //移除关联医院

export const hospMapList = data => createAPI(`base/combo/hosp/map/list`, 'get', data) //查询关联医院









<template>
  <div>
      <q-form ref="myForm" greedy class="q-gutter-md add">
        <div class="row">
          <div class="col kong">
            <!-- 省份选择 -->
            <selectp @provincechange='provincechange' class='add' :options='addqueryDatas.province' :model.sync='addqueryDatas.provinceid' :label='"请选择省份*"' />
          </div>
          <div class="col kong">
            <!-- 城市选择 -->
            <selectc @citychange='citychange' class='add' :options='addqueryDatas.city' :model.sync='addqueryDatas.cityId' :label='"请选择城市*"'/>
          </div>
          <div class="col kong">
            <!-- val => val && val.trim().length > 0 || '医院名称不能为空'  required.addhospname  -->
            <q-input 
              outlined 
              :dense="true" 
              v-model="addqueryDatas.hospName" 
              label="请输入医院" 
              lazy-rules 
              :rules="[
                val => val && val.trim().length > 0 || '医院名称不能为空',
                val =>val && val.trim().length <= 20 || '医院名称不能超过20个字', 
              ]" 
            />
          </div>
        </div>
          <div class="adddepart">
            <q-btn class='kong' :disable='addqueryDatas.hospName.trim().length==0' color="blue-6" label="添加科室" @click='addDeparts'/>
          </div>
          <div>
            <el-table
            :data="addqueryDatas.tableData"
            style="width: 100%"
            max-height="350">
            <el-table-column
              fixed
              prop="date"
              label="科室名称">
            </el-table-column>
            <!-- <el-table-column
              fixed
              prop="datedesc"
              label="科室描述">
            </el-table-column> -->
            <el-table-column
              align='center'
              label="操作"
              width="120">
              <template slot-scope="anniu">
                <el-button
                  type="text"
                  size="small"
                  @click='deldepart(anniu.row)'
                  >
                  移除
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  @click='edit(anniu.row)'
                  >
                  编辑
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          </div>

        <div class="addbtn">
          <el-popover
            placement="bottom"
            width="155"
            v-model="visible">
            <p>未保存的数据将被丢弃, 是否继续?</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="visible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="quxiao">确定</el-button>
            </div>
            <el-button  type="text" slot="reference">取消</el-button>
          </el-popover>
          <!-- <q-btn
              class="kong"
              flat
              label="取消"
              color="primary"
              v-close-popup
              @click="quxiao"
          /> -->
          <q-btn
              class="kong"
              label="添加"
              color="blue-6"
              @click="addHospList"
          />
        </div>
      </q-form>
      <!-- 添加科室弹窗 -->
      <q-dialog v-model="departAdd" persistent @hide='closeadddepart'>
        <q-card style="min-width: 550px">
          <q-card-section>
            <div class="text-h6">添加科室</div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <div class='departtop'>
              <q-form ref="addkeshi" >
                <q-input 
                  outlined 
                  :dense="true" 
                  v-model="departName" 
                  label="请输入科室名称" 
                  :rules="[
                    val => val && val.trim().length > 0 || '科室名称不能为空',
                    val =>val && val.trim().length <= 20 || '科室名称不能超过20个字', 
                  ]" 
                />
              </q-form>
            </div>
            <!-- <div class='departtop'>
              <q-input outlined :dense="true" v-model="departDesc" label="请输入科室描述" />
            </div> -->
          </q-card-section>

          <q-card-actions align="right">
            <q-btn class="kong" flat label="取消" color="primary" v-close-popup />
            <q-btn label="添加" color="primary" @click='okadd'/>
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- 编辑科室弹窗 -->
      <q-dialog v-model="departEdit" persistent>
        <q-card style="min-width: 550px">
          <q-card-section>
            <div class="text-h6">编辑科室</div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <div class='departtop'>
              <q-input outlined :dense="true" v-model="editdepart.departName" label="请输入科室名称" />
            </div>
            <!-- <div class='departtop'>
              <q-input outlined :dense="true" v-model="editdepart.departDesc" label="请输入科室描述" />
            </div> -->
          </q-card-section>

          <q-card-actions align="right">
            <q-btn flat label="保存" color="primary" v-close-popup @click='okedit'/>
          </q-card-actions>
        </q-card>
      </q-dialog>

  </div>
</template>

<script>
import selectp from '@/components/Order/select/provinceselect.vue' // 省份下拉框组件
import selectc from '@/components/Order/select/cityselect.vue' // 城市下拉框组件

import { provinceList,cityList,hospList } from '@/api/City.js'; // 获取城市接口
import { getprovince,getcity,gethosplist,getdepart,getcombo } from '@/api/components/getCity.js'; // 获取城市接口
import { hospdataLists,hospAdd,hospEdit,hospCheck,hospDelete } from '@/api/Hosp.js'; // 套餐增删改查接口

import { comboAdd } from '@/api/Combo.js'; // 套餐增删改查接口
import  {required}  from '@/api/components/required.js' // 表单验证规则
import { notifys } from '@/api/components/Notify.js'

export default {
  created(){
    getprovince(this.addqueryDatas.province)
  },
  
  data() {
    return {
        required,
        text:'',
        visible: false,//控制取消提示

        departAdd: false, //添加套餐弹框
        departName: '', //科室名称
        departDesc: '', //科室描述

        // 编辑科室弹窗信息
        departEdit: false, //添加套餐弹框
        editdepart:{
          departName: '', //科室名称
          departDesc: '', //科室描述
          index: '', //
          uid: ''
        },

        // departNameslist:[], //储存所有的科室名称
        province:[], //省
        city:[], // 市
        hosplist:[], // 医院

        provincevale: null, // 省份选择id值
        required: {
            kong: ()=>{},
            comboName: val => val && val.trim().length > 0 || '套餐名称不能为空',
            comboNamelength: val => val && val.trim().length <= 20 || '套餐名称长度不能超过20个字符',
            comboDesc: val => val && val.trim().length > 0 || '套餐描述不能为空',
            comboDesclength: val => val && val.trim().length <=80 || '套餐描述长度不能超过80个字符',
            comboAmt: val => val && val.trim().length > 0 || '套餐金额不能为空',
            limit: val=> val && val>0 && val<= 999999.99 || '套餐金额必须在0~999999.99之间'
    
        },
    };
  },
  props:{
    addqueryData:{
      type: Object,
      required: true
    }
  },
  components:{
    selectp,
    selectc,
  },
  computed:{
    addqueryDatas:{
      get(){
        return this.addqueryData
      },
      set(v){
        this.$emit('update:addqueryData',v)
      }
    }
  },
  watch:{
  },
  methods: {
    
    // 获取城市
    // async getcity(provinceid){
    //   const { data:res } = await cityList({provinceId:provinceid})
    //   console.log(res);
    //   this.addqueryDatas.city = res.data.cities
    // },
    // 省份选择发生改变时  
    provincechange(){
      this.addqueryDatas.cityId=''
      this.addqueryDatas.city=[]
      this.addqueryDatas.hosp=[]
      console.log(this.addqueryDatas.provinceid);
      getcity(this.addqueryDatas.city,this.addqueryDatas.provinceid.id)
    },
    // 城市变化时获取医院列表
    citychange(){
      this.addqueryDatas.hosp=[]
      gethosplist(this.addqueryDatas.hosp,this.addqueryDatas.cityId.id) //获取医院信息用于检索
      console.log(this.addqueryDatas.hosp);
    },
    quxiao(){
      this.visible = false
      this.$emit("addconfirmclose")
    },
    // 添加医院
    addHospList(){
      this.$refs.myForm.validate().then( async outcome =>{
        if (outcome){
          var shifouadd = true
          // 保存之前检查添加医院是否已经存在
          this.addqueryDatas.hosp.map(item=>{
            if(item.hospName == this.addqueryDatas.hospName){
              this.$q.dialog({
                title: '提醒',
                message: '您添加的医院已存在,请修改医院名称',
                ok: { label: '确定'},
              })
              shifouadd=false
            }
          })
          if(shifouadd){
            console.log(111);
            this.addqueryDatas.tableData.map((item)=>{
              this.addqueryDatas.departNameslist.push(item.date)
            })
            console.log(this.departNameslist);
            const { data:res } = await hospAdd({
              cityId:this.addqueryDatas.cityId.id,
              hospName: this.addqueryDatas.hospName,
              departNames: this.addqueryDatas.departNameslist
            })
            console.log(res);
            if(res.code===20000){
              this.$emit("addconfirmclose") //关闭并清空内容
              this.$emit("addcombosuccess") //刷新医院列表
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }
        }
      })
    },

    //点击添加科室
    addDeparts(){
      this.departAdd =true
    },
    // 关闭添加科室窗口时
    closeadddepart(){
      this.departName=''
      this.departDesc=''
    },
    // 添加可以保存
    okadd(){
      this.$refs.addkeshi.validate().then( async outcome =>{
        if(outcome){
          if(this.departName.trim().length!=0){
            this.addqueryDatas.tableData.push({
              date: this.departName,
              datedesc: this.departDesc,
              uid: Math.random()
            })
            this.departName=''
            this.departDesc=''
            this.departAdd =false
          }
        }
      })
    },
    // 移除科室
    deldepart(row){
      const index = this.addqueryDatas.tableData.findIndex(item => item.uid === row.uid)
      console.log(index);
      this.addqueryDatas.tableData.splice(index,1)
    },

    // 点击编辑
    edit(row){
      const index = this.addqueryDatas.tableData.findIndex(item => item.uid === row.uid)
      this.editdepart.departName = row.date
      this.editdepart.departDesc = row.datedesc
      this.editdepart.uid = row.uid
      this.editdepart.index = index
      this.departEdit = true
    },
    // 编辑科室保存
    okedit(){
      console.log(this.editdepart.index);
      this.addqueryDatas.tableData.splice(this.editdepart.index,1,{
        date: this.editdepart.departName,
        datedesc: this.editdepart.departDesc,
        uid: Math.random()
      })
      // this.tableData.push()
      this.editdepart.departName=''
      this.editdepart.editdepart=''
      this.editdepart.index=''
      this.editdepart.uid=''
      this.departEdit = false
      // this.tableData[index].date =this.editdepart.departName
      // this.tableData[index].datedesc =this.editdepart.departDesc
    }
  },
};
</script>

<style lang='less' scoped>
.adddepart{
  display: flex;
  flex-direction: row-reverse;
  margin-top: 0;
}
.departtop{
  margin: .625rem 0;
}
.kong{
  margin-left: 1.25rem !important;
}
.operation{
    width: 100%;
    margin-top: 0;
}
.addbtn {
    display: flex;
    justify-content: flex-end;
}
</style>

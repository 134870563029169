<template>
  <div id="hosp" class="q-pa-md q-gutter-sm">
    <!-- 面包屑 -->
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
      <q-breadcrumbs-el label="基础数据管理" icon="iconfont icon-ziyuan143" />
      <q-breadcrumbs-el label="医院管理" icon="iconfont icon-yiyuan" to="combo/list" />
    </q-breadcrumbs>
    <!-- 总卡片区 -->
    <div ref="hospbox" id="hospbox" class="hospbox">
      <!-- 搜索卡片区 -->
      <q-card class="my-card" style="margin-bottom: 10px;">
        <q-card-section>
          <div class="searchbox" id="searchbox">
              <!-- 搜索框 -->
              <div class="search">
                  <div>
                    <el-select @change="provincechange" @clear='clearpro' clearable  class='operation' filterable  v-model="queryData.provincevale" placeholder="请选择省份">
                      <el-option
                        v-for="item in province"
                        :key="item.id"
                        :label="item.provinceName"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </div>
                  <div>
                    <el-select class='operation' clearable @clear='queryData.cityId=null' filterable  v-model="queryData.cityId" placeholder="请选择城市">
                      <el-option
                        v-for="item in city"
                        :key="item.id"
                        :label="item.cityName"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </div>
                  <!-- <div class="col">
                    <el-select class='operation' filterable  v-model="queryData.hospId" placeholder="请选择医院">
                      <el-option
                        v-for="item in hosplist"
                        :key="item.id"
                        :label="item.hospName"
                        :value="item.id">
                      </el-option>
                  </el-select>
                  </div> -->
                  <div>
                    <q-input class='operation' :dense='true' outlined  v-model="queryData.hospName" label="医院名称">
                      <template v-if="queryData.hospName" v-slot:append>
                        <q-icon name="close" @click.stop="queryData.hospName = ''" class="cursor-pointer" style="font-size: 16px;"/>
                      </template>
                    </q-input>
                  </div>
                  <el-button  
                    :disabled='Permissionslist.filter(item=>item==="hosp.list").length>0?false:true'
                    size='medium' 
                    @click='search'
                  >
                    搜索
                  </el-button>
                  <el-button
                  :disabled='Permissionslist.filter(item=>item==="hosp.list").length>0?false:true'
                  size='medium'
                  @click='reset'
                  >
                    重置
                  </el-button>
              </div>
              <!-- 增查区 -->
              <div class="search">
                  <el-button
                  :disabled='Permissionslist.filter(item=>item==="hosp.add").length>0?false:true'
                  size='medium'
                  @click='addconfirm = true'>
                      新增医院
                  </el-button>
              </div> 
          </div>
        </q-card-section>
      </q-card>
       <!-- 列表区域 -->
      <q-card class="my-card">
        <q-card-section>
          <el-table
          v-loading="ordervisible"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(55,55,55,0.2)"
          ref="multipleTable"
          :data="allhosp"
          tooltip-effect="dark"
          :max-height="tableHeight"
          style="width: 100%"
          >
          <template slot="empty">
            {{nodatetype}}
          </template>
          <el-table-column align='center' prop="provinceName" label="省份" ></el-table-column>
          <el-table-column align='center'  prop="cityName" label="城市" ></el-table-column>
          <el-table-column show-overflow-tooltip align='center'  prop="hospName"  label="医院名称" min-width="114px"></el-table-column>
          <el-table-column align='center' prop="createTime" label="创建时间"></el-table-column>
          <el-table-column align='center' label="操作" width="200" fixed="right">
            <template slot-scope="anniu">
              <q-btn
                :disable='Permissionslist.filter(item=>item==="hosp.edit").length>0?false:true'
                class="czbtn" 
                size="sm" 
                color="blue" 
                icon="iconfont icon-Edit" 
                @click="editCombo(anniu.row)"
                >
                </q-btn>
                <q-btn
                :disable='Permissionslist.filter(item=>item==="hosp.delete").length>0?false:true'
                size="sm" 
                color="red-6" 
                icon="iconfont icon-ashbin" 
                @click="delCombo(anniu.row)"
                >
                </q-btn>
            </template>
          </el-table-column>
          
        </el-table>
          <!-- 分页组件 -->
        <elementPagination class="Paginationstyle" :total='total' :queryData='queryData' @getList='getreconList()' @changepageSize='changepageSize' @changePage='changePage'/>
        </q-card-section>
      </q-card>
      <!-- 列表区域 -->
      <!-- <q-table
          style='margin-top:10px;'
          title="Treats"
          :data="allhosp"
          :columns="columns"
          :pagination="pagination"
          :hide-pagination="true"
          row-key="name"
        >
            空白顶部
            <template v-slot:top></template> 
            <template v-slot:body-cell-operation="anniu">
              <q-td :props="anniu">
                <q-btn
                :disable='Permissionslist.filter(item=>item==="combo.edit").length>0?false:true'
                class="czbtn" 
                size="sm" 
                color="blue" 
                icon="iconfont icon-Edit" 
                @click="editCombo(anniu.row)"
                >
                </q-btn>
                <q-btn
                :disable='Permissionslist.filter(item=>item==="combo.delete").length>0?false:true'
                size="sm" 
                color="red-6" 
                icon="iconfont icon-ashbin" 
                @click="delCombo(anniu.row)"
                >
                </q-btn>
              </q-td>
            </template>
            <template v-slot:bottom>
          <elementPagination :total='total' :queryData='queryData'  @changepageSize='changepageSize' @changePage='changePage'/>
              
            </template>
            无数据时显示
            <template v-slot:no-data="">
              <div class="full-width row flex-center text-accent q-gutter-sm">
                <span style="color: #909399;font-size: 14px;">
                  暂无数据
                </span>
              </div>
            </template>
          </q-table> -->
    </div>
    <!-- 新增弹窗 -->
    <el-dialog :visible.sync="addconfirm" width="70%" title="新增医院" :close-on-click-modal='false' @close="adddialogclose">
      <hospadd
        ref='comboadd'
        :addqueryData.sync='addqueryData'
        @addconfirmclose='adddialogclose'
        @addcombosuccess='gethospdatalist'
      >
      </hospadd>
    </el-dialog>  
     <!-- 编辑弹窗 -->
    <el-dialog :visible.sync="editconfirm" width="70%" title="编辑医院" :close-on-click-modal='false' @close="editdialogclose">
      <hospedit
        ref='comboedit'
        :addqueryData.sync='editAllData'
        :editData.sync='editData'
        :checkAlllist.sync='checkAlllist'
        @editdialogclose='editdialogclose'
        @editcombosuccess='gethospdatalist'
      >
      </hospedit>
    </el-dialog>  
  </div>
  
</template>

<script>
import hospadd from '@/components/Hosp/addHosp.vue' // 添加套餐组件
import hospedit from '@/components/Hosp/editHosp.vue' // 添加套餐组件
import { makeDebounce } from "@/utils/debounce.js";

import elementPagination from '@/components/ElementUI/elementPagination.vue' // 分页组件
import { provinceList,cityList,hospList,departList } from '@/api/City.js'; // 获取城市接口
import { getprovince,getcity,gethosplist,getdepart,getcombo,getoptions,optionget } from '@/api/components/checkcitys.js'; // 获取城市接口
import { hospdataLists,hospAdd,hospEdit,hospCheck,hospDelete } from '@/api/Hosp.js'; // 医院增删改查接口
import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度
import { mapState } from 'vuex'
import { notifys } from '@/api/components/Notify.js'

export default {
  created(){
    this.gethospdatalist()
    this.getprovince()
    
  },
  mounted() {
    
  },
  data() {
    return {
        province:[], //省
        city:[], // 市
        hosplist:[], // 医院
        depart:[], // 科室

        tableHeight:0, // 列表最大高度

        ordervisible: false, //控制loading
        nodatetype:'', //无内容时显示
        // 列表头部信息
        columns: [
          {
            name: 'provinceName',
            required: true,
            label: '省份',
            align: 'left',
            field: row => row.provinceName,
            format: val => `${val}`,
            headerClasses: 'my-special-class'
          },
          { name: 'cityName',align: 'center', label: '城市', field: 'cityName',headerClasses: 'my-special-class' },
          { name: 'hospName',align: 'center', label: '医院名称', field: 'hospName',headerClasses: 'my-special-class'},
          // { name: 'comboDesc',align: 'center', label: '套餐描述', field: 'comboDesc'},
          { name: 'createTime',align: 'center', label: '创建时间', field: 'createTime',headerClasses: 'my-special-class'},
          // { name: 'state',align: 'center', label: '状态', field: 'state'},
          { name: 'operation',align: 'center', label: '操作', field: 'operation',headerClasses: 'my-special-class'}
        ],
        allhosp:[], //所有套餐数据
        
        queryData:{ // element分页信息
          isNeedPage: true,
          provincevale: null, // 省份选择id
          hospName:null,
          page: 1,
          pageSize: 10,
          cityId: null,
          // hospId: null
        },
        total:0, //总条数
        pagination: {//quasar分页设置 
          page: 1,
          rowsPerPage: 20, //设置默认是显示多少条,
        },

        addconfirm: false, // 控制新增窗口
        addqueryData:{
          hospName:'',
          cityId:'',
          provinceid:'',
          province: [],
          city:[],
          hosp:[], //储存医院
          tableData:[],//储存所有科室
          departNameslist:[], //储存所有科室名称
        },

        editconfirm: false, // 控制修改窗口
        editAllData:{}, //修改套餐所需信息
        editData:{ // 查看用户所需数据
          provinceoptions: null, // 省份所选项
          cityoptions: null, // 城市所选项
          hospId:'', //储存此条医院id
          chaxun:'', //查找科室
        },
        // 省份到套餐总数据
        checkAlllist:{
          province:[],
          city:[],
          depart:[],
        },
      };
  },
  components:{
    elementPagination,
    hospadd,
    hospedit
  },
  computed:{
    ...mapState([
      'Permissionslist' // 权限词典
    ]),
    tableHeights(){
      return this.tableHeight
    }
  },
  watch:{
    tableHeights(s,v){
      console.log(s);
      console.log(v);
      this.tableHeight = s
    }
  },
  methods: {
    // 医院列表
    async gethospdatalist(){
      this.nodatetype=''
      this.ordervisible = true
      setTimeout(async() =>{
        const { data:res } = await hospdataLists({
          hospName: this.queryData.hospName,
          cityId: this.queryData.cityId,
          provinceId: this.queryData.provincevale,
          page: this.queryData.page,
          pageSize:  this.queryData.pageSize,
          isNeedPage: this.queryData.isNeedPage
        })
        console.log(res);
        if(res.code ===20000){
          this.allhosp = res.data.hosps
          // console.log(this.allhosp);
          this.total = res.data.totals * 1
          // if(this.total/this.queryData.pageSize<this.queryData.page){
          //   if(this.total/this.queryData.pageSize<1){
          //     this.queryData.page =1
          //   }else{
          //     this.queryData.page = (parseInt(this.total/this.queryData.pageSize) + 1)*1
          //   }
          // }
        }
        this.ordervisible = false
        this.nodatetype='暂无数据'

        let height = document.getElementById('searchbox').offsetHeight + 85
        console.log(height);
        this.tableHeight = getHeight('hospbox',height)
      },500)
    },
    // 获取省份信息
    async getprovince(){
      const { data:res } = await provinceList()
      console.log(res);
      this.province = res.data.provinces
    },
    // 获取城市
    async getcity(provinceid){
      const { data:res } = await cityList({provinceId:provinceid})
      console.log(res);
      this.city = res.data.cities
    },
    // 获取科室信息
    // async getdepart(hospid){
    //   const { data:res } = await hospList({hospId:hospid})
    //   console.log(res);
    // },
    
    // 省份选择发生改变时
    provincechange(){
        console.log(this.queryData.provincevale);
        if(this.queryData.provincevale){
          this.getcity(this.queryData.provincevale)
        }
        this.queryData.cityId=null
        this.queryData.hospId=null
        this.city=[]
    },
    // 清空省份
    clearpro(){
      this.queryData.provincevale=null
      this.queryData.cityId=null
      this.city=[]
    },
    // 点击搜索
    search:makeDebounce(function(){
      this.queryData.page = 1
      this.gethospdatalist()
    },200),
    // 点击重置
    reset:makeDebounce(function(){
      this.queryData.page = 1
      this.queryData.hospName=null
      this.queryData.cityId=null
      this.queryData.provincevale=null
      this.city=[]
      this.gethospdatalist()
    },200),
    // 点击修改
    editCombo:makeDebounce(async function(row) {
      const { data: res } = await hospCheck({
        id:row.id,
      })
      console.log(res);
      if(res.code ===20000){
        this.editAllData = res.data.hospVo
        console.log(this.editAllData);
        this.checkAlllist.province= await getprovince() // 获取所有省份
        this.checkAlllist.city= await getcity(this.editAllData.provinceId) // 获取所有城市
        // 获取城市所选项
        this.editData.cityoptions = getoptions(this.checkAlllist.city,this.editAllData.cityId)
        // 获取省份所选项
        this.editData.provinceoptions = getoptions(this.checkAlllist.province,this.editAllData.provinceId);
        // 储存医院id
        this.editData.hospId = this.editAllData.id
        // 获取医院拥有的科室信息
        this.checkAlllist.depart= await getdepart(this.editAllData.id)//获取所有科室
        this.editconfirm = true
      }
    }),
    // 修改弹窗关闭时触发
    editdialogclose(){
      this.checkAlllist.depart=[]
      this.editData.hospId=''
      this.editData.chaxun=''
      this.editconfirm =false
      this.$refs.comboedit.$refs.myForm.resetValidation();
    },
    //点击删除
    async delCombo(row){
      console.log(row);
      this.$q.dialog({
        title: '删除',
        message: `你确定要删除医院 ${row.hospName} 吗`,
        ok: { label: '确定',color: 'red'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
            const { data: res } = await hospDelete({
              id: row.id
            })
            if(res.code===20000){
              this.gethospdatalist()
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }catch (e){
              console.log(e);
          }
      })
    },
    // 添加对话框关闭触发
    adddialogclose(){
      this.addqueryData.tableData=[] //清空储存的科室
      this.addqueryData.departNameslist=[] // 清空科室名称数组
      this.addqueryData.hospName=''
      this.addqueryData.provinceid =''
      this.addqueryData.cityId =''
      this.addqueryData.city =[]
      this.addconfirm =false
      this.$refs.comboadd.$refs.myForm.resetValidation();
    },
    
    // 分页发送变化函数
    changepageSize(val){
      this.queryData.pageSize = val
      this.pagination.rowsPerPage = val
      this.gethospdatalist()
    },
    changePage(val){
      this.gethospdatalist()
      this.queryData.page = val
      // if(this.total/this.queryData.pageSize<val){
      //   if(this.total/this.queryData.pageSize<1){
      //     this.queryData.page =1
      //   }else{
      //     this.queryData.page = (parseInt(this.total/this.queryData.pageSize) + 1)*1
      //   }
      // }else{
      //   this.queryData.page = val
      // }
    }
  },
};
</script>

<style lang='less' scoped>
#hosp{
  height: 100%;
}
.hospbox{
  height: 90%;
}
.searchbox{
  display: flex;
  justify-content: space-between;
  .search{
    display: flex;
    flex-direction: row ;
    align-items: flex-end;
    // margin-bottom: 1.25rem;
    .operation{
      height: 2.25rem;
      width: 90%;
      margin: .625rem;
    }
  }
}

.el-button{
  background: #2196f3 !important;
  height: 2.25rem;
  color: #fff;
  margin: 0 .625rem .625rem .625rem;
}
.addbtn{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
  .el-button{
    background: #2196f3 !important;
    height: 2.25rem;
    color: #fff;
    margin: 0 .625rem .625rem .625rem;
  }
}
 .czbtn{
    margin-right: .75rem;
  }
/deep/.my-special-class{
  font-size: 14px;
  font-weight: bold;
  color: #909399;
}
.Paginationstyle{
  margin-top: .9375rem;
}
// style="width: 13.5625rem;" 搜索框专用
</style>
